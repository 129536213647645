import type { HTMLAttributes } from 'react';
import type React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import { clsx } from '../utils/styles';
interface QuestionTooltipProps extends HTMLAttributes<HTMLSpanElement> {
	tooltip: string;
	tooltipClassName?: string;
}

export const QuestionTooltip: React.FC<QuestionTooltipProps> = ({
	tooltip,
	tooltipClassName,
	...rest
}) => {
	const { className, ...spanProps } = rest;
	return (
		<span
			className={clsx('has-kmodo-tooltip mx-1 inline cursor-pointer align-text-bottom', className)}
			{...spanProps}
		>
			<BsQuestionCircle className={'inline align-text-bottom'} />
			<div
				className={clsx(
					'kmodo-tooltip top-full m-2 w-[20rem] rounded border bg-gray-50 bg-white p-2 shadow-lg',
					tooltipClassName,
				)}
			>
				<p className={'whitespace-pre-wrap text-left text-xs font-normal'}>{tooltip}</p>
			</div>
		</span>
	);
};
